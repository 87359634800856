<template>
  <section :class="{ 'noshow' : closed }">
    <transition name="appear">
      <div v-if="timeCompleted" id="wrapper-popup">
        <a
          href="#"
          aria-label="Close popup"
          @click.prevent="close()"
        >
          <Close class="close" />
        </a>
        <div class="mb-3 bg-turquoise rounded-lg">
          <h3 class="m-2 text-black" v-text="popup.titel" />
        </div>
        <div class="mb-3">
          <div v-html="popup.text" />
        </div>
        <div class="text-center">
          <Go
            class="button rounded-lg"
            :to="popup.ctaziel"
            v-text="popup.cta"
          />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>

import Go from '@/components/utils/Go.vue';
import Close from '@/assets/images/close.svg';

export default {
  components: { Go, Close },
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      timeCompleted: false,
      closed: false,
    };
  },
  computed: {
    popup() {
      return this.$store.state.router.layout.popup;
    },
  },
  mounted() {
    if (localStorage.getItem('popupClosed') === 'true') {
      this.closed = true;
    } else {
      setTimeout(() => { this.timeCompleted = true; }, 10000);
    }
  },
  methods: {
    close() {
      localStorage.setItem('popupClosed', 'true');
      this.timeCompleted = false;
      setTimeout(() => { this.closed = true; }, 1000);
    },
  },

};

</script>

<style scoped lang="scss">

.noshow {
  display: none;
}

#wrapper-popup {
  position: fixed;
  z-index: 999;
  padding: 2rem 0 1rem 1rem;
  right: 0;
  top: 5rem;
  @apply rounded-lg;
  width: 100%;
  max-width: none;
  background: var(--color-lightgray);
  overflow: scroll;
  max-height: calc(100% - 5rem);

  @media print {
    display:none;
  }

  @screen sm {
    left: inherit;
    max-width: 30rem;
    @apply rounded;
  }

  :deep(ul) {
    font-size: 0.8rem;
  }
  :deep(p) {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  :deep(h4) {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.0rem;
    line-height: 1.2;
    margin-bottom: .3em;
    color: var(--color-darkgray);
  }
  :deep(h3) {
    font-size: 1.2rem;
  }

  .close {
    position: absolute;
    @apply right-0;
    @apply text-black;
    top: -0.5rem;
    width: 3rem;
    padding: 1rem;
    cursor: pointer;
  }

}
</style>
