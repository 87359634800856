<template>
  <div :class="{down: !showNavbar, onTop}">
    <slot />
  </div>
</template>

<script>
export default {
  data: () => ({
    showNavbar: true,
    onTop: true,
    lastScrollPosition: 0,
  }),
  watch: {
    showNavbar() {
      if (this.showNavbar) document.body.classList.add('showNavbar');
      else document.body.classList.remove('showNavbar');
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      this.onTop = currentScrollPosition < 100;

      if (currentScrollPosition <= 0) {
        this.showNavbar = true;
      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) return;

      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
