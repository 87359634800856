function cleanSvgs(el) {
  if (!el) return;

  const svgs = [...el.querySelectorAll('svg')];

  svgs.forEach((svg) => {
    svg?.removeAttribute('width');
    svg?.removeAttribute('height');
  });
}

// eslint-disable-next-line import/prefer-default-export
export const cleanSvgDirective = {
  beforeMount: cleanSvgs,
  updated: cleanSvgs,
};
