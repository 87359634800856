<template>
  <HideOnScroll
    id="nav"
    :class="{'white-header' : !frontPage && !mobile, 'navigation-blur': $store.state?.router?.navigationBlur}"
    v-bind="$attrs"
  >
    <div class="relative px-4 sm:px-12 flex outer">
      <nav class="inner w-full flex items-center">
        <Go
          :to="'/' + $store.state.router.locale"
          aria-label="Home"
          class="home mr-auto flex"
        >
          <template v-if="$store.state.router.sub === '/klinova'">
            <Klinova />
          </template>
          <template v-else>
            <Wetter />
          </template>
          <span v-if="$store.state.router.beta" class="font-bold">[BETA]</span>
        </Go>
        <transition-group name="default">
          <div
            v-for="link of navigation?.links"
            :key="link.uri"
            class="nav-element dropdown"
          >
            <Go
              v-if="!link.hide"
              :to="link.uri"
              class="px-4 link font-bold"
              :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
              v-text="link.title"
            />
            <div v-if="link.subs.length > 0" class="dropdown-content">
              <template v-for="sublink of link.subs" :key="sublink.uri">
                <Go
                  v-if="!link.hide"
                  :to="sublink.uri"
                  class="link mb-1 px-4"
                  :class="{ active: sublink.uri === $store.state.router.page.meta?.uri }"
                  v-text="sublink.title"
                />
              </template>
            </div>
          </div>
          <div key="dropdown-marker" class="nav-element dropdown marker">
            <Marker class="px-4 link font-bold" />
            <div class="dropdown-content">
              <template v-for="location of locations" :key="location.name">
                <Go
                  :to="location.uri"
                  class="link mb-1 px-4"
                  :class="{ active: location.sub === $store.state.router.sub }"
                  v-text="location.name"
                />
              </template>
            </div>
          </div>
        </transition-group>
      </nav>
      <Go
        :to="'/' + $store.state.router.locale"
        aria-label="Home"
        class="home sm:hidden"
      >
        <template v-if="$store.state.router.sub === '/klinova'">
          <Klinova />
        </template>
        <template v-else>
          <Wetter />
        </template>
      </Go>
      <Hamburger
        class="sm:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />
    </div>
  </HideOnScroll>

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <template
          v-for="link of navigation?.links"
          :key="link.uri"
        >
          <Go
            v-if="!link.hide"
            :to="link.uri"
            class="px-4 link"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            @click="toggle(true)"
            v-text="link.title"
          />
        </template>
        <hr class="pb-6">
        <div class="px-4 marker">
          <Marker />
        </div>
        <template v-for="location of locations" :key="location.name">
          <Go
            :to="location.uri"
            class="link mb-1 px-4"
            :class="{ active: location.sub === $store.state.router.sub }"
            v-text="location.name"
          />
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Scrolllock from '@/hooks/scrolllock';
import Hamburger from '@/components/utils/Hamburger.vue';
import Klinova from '@/assets/images/logo.svg';
import Wetter from '@/assets/images/wetter-logo.svg';
import Marker from '@/assets/images/marker.svg';
import HideOnScroll from '@/components/utils/HideOnScroll.vue';
import Go from '@/components/utils/Go.vue';

export default {
  components: {
    Hamburger,
    Klinova,
    Wetter,
    Marker,
    HideOnScroll,
    Go,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
    locations: [
      {
        name: 'Zürich - Klinova AG',
        uri: 'http://klinova.ch',
        sub: '/klinova',
      },
      {
        name: 'Wetzikon - Wetter Gebäudetechnik AG',
        uri: 'http://wetter-wetzikon.ch',
        sub: '/wetter',
      },
    ],
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    frontPage() {
      const { page } = this.$store.state.router;
      if (page && page.content?.[0].component === 'Hero') {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  svg {
    height: 2.5rem;
    width: auto;
  }
}

.marker {
  svg {
    height: 2rem;
    width: auto;
  }
}

#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  padding: 1rem 0;
  transition: .15s ease;

  &.onTop {
    height: 70px;
  }

  &.navigation-blur {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 40%, rgba(255, 255, 255, 0.75) 100%);
  }

  @media (max-width: theme("screens.sm")) {
    padding-top: 20px;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }

    .outer {
      justify-content: space-between;
    }
  }

  background-color: transparent;

  &.white-header {
    background-color: white;
  }

  &.down {
    transform: translateY(-300%);
  }

}

.link {
  font-size: 1.2rem;
  @apply font-bold;

  &.active {
    @apply text-turquoise;
  }

}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;

  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    pointer-events: all;
  }
}

hr {
  color: rgba(0, 0, 0, 0.6);
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    font-size: 2rem;
    padding: 0.8rem 1rem;
  }
}

.dropdown {
  position: relative;

  &:hover {
    .dropdown-content {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }

  .dropdown-content {
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    @apply pb-2 pt-2;
    width: 300px;
    position: absolute;
    top: 100%;
    right: 0;
    @media screen and (min-width: 2500px) {
      width: 350px;
    }
  }
}

.white-header .dropdown-content {
  background: white;
}

.showNavbar :not(.onTop)#nav .dropdown-content {
  background: white;
}
</style>
