import {
  onMounted, onUnmounted, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
import { pageview } from 'vue-gtag';

export default function () {
  const store = useStore();

  function popstate(history) {
    store.dispatch('router/pop', { path: window.getPath(), data: history.state });
  }

  const meta = computed(() => store.state.router.popup?.meta || store.state.router.page?.meta);
  useHead({
    htmlAttrs: { lang: computed(() => store.state.router.locale) },
    title: computed(() => (store.state.router.beta ? '[BETA] ' : '') + (meta.value?.title || '')),
    meta: computed(() => [
      { name: 'description', content: meta.value?.description },
      { property: 'og:description', content: meta.value?.description },
      { property: 'og:title', content: meta.value?.title },
      { property: 'og:type', content: 'website' },
      { property: 'robots', content: store.state.router.beta ? 'noindex' : undefined },
      // eslint-disable-next-line max-len
      { name: 'google-site-verification', content: store.state.router.sub === '/klinova' ? 'gROKGZGtBTNxbe0MhsG5ERcPjRGiwgDni2A8xSTqtfw' : '3ttHC28qEuul3XkvCEr5Yk1AlUGRih977r__c5es93Y' },
    ]),
    link: computed(() => {
      if (!meta.value) return [];
      const tags = [];
      Object.entries(meta.value.translatedPaths)
        .forEach((e) => {
          tags.push({ rel: 'alternate', hreflang: e[0], href: e[1] });
        });
      return tags;
    }),
  });

  watch(() => store.state.router.page, () => {
    pageview({ page_title: meta.value?.title, page_path: window.location.pathname });
  });

  onMounted(async () => {
    try {
      await store.dispatch('router/init', { uri: window.getPath(), hydrate: !!window.INITAL_ROUTER });
    } catch (err) {
      console.error('Server unreachable', err);
    }
    window.addEventListener('popstate', popstate);
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', popstate);
  });
}
