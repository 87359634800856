import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import VueGoogleMaps from 'vue-google-maps-community-fork';
import VueGtag from 'vue-gtag';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';

import store from './store';
import checkView from './utils/vue-check-view';
import { cleanSvgDirective } from './utils/svg';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(VueGoogleMaps, { load: { key: 'AIzaSyCY_b7Km4-GhMcFR0NIH1RhVVgl3UeKxfU' } });

if (!window.prerender && window.env.PUBLIC_GA) {
  app.use(VueGtag, {
    config: { id: window.env.PUBLIC_GA },
    enabled: false,
    bootstrap: !window.dataLayer,
  });
}

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');

window.SUB_MAP = {
  '/klinova': 'Klinova AG',
  '/wetter': 'Wetter Gebäudetechnik AG',
};

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.directive('clean-svgs', cleanSvgDirective);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
