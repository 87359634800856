<template>

  <div id="overlay" />

  <Navigation :class="{'white-header' : !frontPage}" />
  <main :class="{'white-header' : !frontPage}">
    <Dynamic v-if="content" :inject="content" />
  </main>

  <Cookie />
  <PopUp v-if="showPopup" />
</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';
import Cookie from '@/components/utils/Cookie.vue';
import PopUp from '@/components/utils/PopUp.vue';

export default {
  components: {
    PopUp,
    Cookie,

    Navigation,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
    frontPage() {
      const { page } = this.$store.state.router;
      if (page && page.content?.[0].component === 'Hero') {
        return true;
      }
      return false;
    },
    showPopup() {
      return this.$store.state.router.layout?.showPopup;
    },
  },

};
</script>

<style lang="scss">
.white-header {
  padding-top: 5rem;
}
.showNavbar .onTop {
  background-color: transparent;
}
.showNavbar :not(.onTop)#nav {
  background-color: white;
  box-shadow: 3px 3px 3px var(--color-lightgray);
}
</style>
