<template>
  <transition name="appear">
    <div v-if="!cookie && general" id="cookie-wrapper">
      <div class="mb-8">
        <p class="mb-0" v-text="general.text" />
        <Go :to="general.link" v-text="general.linktext" />
      </div>
      <div class="flex gap-4">
        <button
          class="button white"
          :arrow="false"
          @click="cookie = 'decline'"
        >
          Ablehnen
        </button>
        <button
          class="button"
          :inverted="true"
          :arrow="false"
          @click="cookie = 'Allow'"
        >
          Akzeptieren
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { useStore } from 'vuex';
import { optIn as enableGtag } from 'vue-gtag';

const store = useStore();

const cookie = useStorage('cookie', '');

const general = computed(() => store.state.router?.layout?.cookie);

watch(
  [cookie],
  (a) => {
    // not accepted
    if (!a[0] || a[0] === 'decline') return;
    // not in prerender
    if (window.prerender) return;
    // can be enabled
    if (!window.env.PUBLIC_GA) return;
    // start tracking
    enableGtag();
  },
  { immediate: true },
);

</script>

<style scoped lang="scss">
#cookie-wrapper {
  position: fixed;
  z-index: 1000;
  padding: 1.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  @apply rounded-t;
  width: 100%;
  max-width: none;
  background: var(--color-lightgray);

  @media print {
    display:none;
  }

  @screen md {
    left: inherit;
    right: 2rem;
    bottom: 2rem;
    max-width: 25rem;
    @apply rounded;
  }

  :deep(a) {
    border-bottom: 1px solid currentColor;
  }

  .white {
    @apply bg-white;
    @include hover {
      @apply bg-black;
    }
  }

}
</style>
