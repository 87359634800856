<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.data.slug"
      :key="component.data.id"
      :payload="component.data"
      class="fade-item"
    >
      <h2>Unable to load component «{{ component.component }}».</h2>
    </component>
    <Footer v-if="!noFooter" key="footer" class="fade-item" />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

    Text: defineAsyncComponent(() => import('@/components/parts/Text.vue')),
    Image: defineAsyncComponent(() => import('@/components/parts/Image.vue')),
    Hero: defineAsyncComponent(() => import('@/components/parts/Hero.vue')),
    Slider: defineAsyncComponent(() => import('@/components/parts/Slider.vue')),
    Contact: defineAsyncComponent(() => import('@/components/parts/Contact.vue')),
    DreiBilder: defineAsyncComponent(() => import('@/components/parts/DreiBilder.vue')),
    Kacheln: defineAsyncComponent(() => import('@/components/parts/Kacheln.vue')),
    Project: defineAsyncComponent(() => import('@/components/parts/Project.vue')),
    Downloadcenter: defineAsyncComponent(() => import('@/components/parts/Downloadcenter.vue')),
    ProjectInfo: defineAsyncComponent(() => import('@/components/parts/ProjectInfo.vue')),
    TopReferenzen: defineAsyncComponent(() => import('@/components/parts/Top-Referenzen.vue')),
    FilterTopReferenzen: defineAsyncComponent(() => import('@/components/parts/Top-Referenzen.vue')),
    GleicheProjekte: defineAsyncComponent(() => import('@/components/parts/Top-Referenzen.vue')),
    KundenProjekte: defineAsyncComponent(() => import('@/components/parts/Top-Referenzen.vue')),
    WetterTopReferenzen: defineAsyncComponent(() => import('@/components/parts/Top-Referenzen.vue')),
    ReferenzenFilter: defineAsyncComponent(() => import('@/components/parts/Referenzen-Filter.vue')),
    SplittedBanner: defineAsyncComponent(() => import('@/components/parts/SplittedBanner.vue')),
    Team: defineAsyncComponent(() => import('@/components/parts/Team.vue')),
    Werte: defineAsyncComponent(() => import('@/components/parts/Werte.vue')),
    Zitat: defineAsyncComponent(() => import('@/components/parts/Zitat.vue')),
    TextVideo: defineAsyncComponent(() => import('@/components/parts/Text-Video.vue')),
    Vorteile: defineAsyncComponent(() => import('@/components/parts/Vorteile.vue')),
    Dienstleistungen: defineAsyncComponent(() => import('@/components/parts/Dienstleistungen.vue')),
    Gallery: defineAsyncComponent(() => import('@/components/parts/Gallery.vue')),
    ProjectFactAndFigures: defineAsyncComponent(() => import('@/components/parts/ProjectFactAndFigures.vue')),
    Employee: defineAsyncComponent(() => import('@/components/parts/Employee.vue')),
    ReferenzenMap: defineAsyncComponent(() => import('@/components/parts/ReferenzenMap.vue')),
    Stellen: defineAsyncComponent(() => import('@/components/parts/Stellen.vue')),
    StellenMitButton: defineAsyncComponent(() => import('@/components/parts/StellenMitButton.vue')),
    JobDynamic: defineAsyncComponent(() => import('@/components/parts/JobDynamic.vue')),
    NewsDynamic: defineAsyncComponent(() => import('@/components/parts/NewsDynamic.vue')),
    BewerbungsFormular: defineAsyncComponent(() => import('@/components/parts/BewerbungsFormular.vue')),
    Kontaktformular: defineAsyncComponent(() => import('@/components/parts/Kontaktformular.vue')),
    News: defineAsyncComponent(() => import('@/components/parts/News.vue')),
    StandortMap: defineAsyncComponent(() => import('@/components/parts/StandortMap.vue')),
    HTMLElement: defineAsyncComponent(() => import('@/components/parts/HTMLElement.vue')),
    Buttons: defineAsyncComponent(() => import('@/components/parts/Buttons.vue')),
    Konfigurator: defineAsyncComponent(() => import('@/components/parts/Konfigurator.vue')),

  },
  props: { inject: { type: Object, default: Object }, noFooter: Boolean },
  computed: {
    parts() {
      if (!Array.isArray(this.inject)) {
        const arr = [];
        arr.push(this.inject);
        return arr.filter((part) => !part.data?.hidden);
      }
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
